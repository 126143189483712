.event-page {
    &__already-registered {
      font-weight: bold;
      color: #284;
    }

    &__container {
        &__top {
          img {
            width: 100%;
          }
        }

        &__bottom {
            margin-top: 30px;
        }
    }
    &__top {
        width:100%;
    }
    &__bottom {
        display:flex;
        flex-direction: column;
        @media (min-width: $small-breakpoint) {
            flex-direction: row;
        }
      &__left {
          margin: 20px 0;
      }
      &__right {
        min-width:324px;
        max-width:324px;
        @media (min-width: $medium-breakpoint) {
            margin-left:30px;
        }
        &__keyinfo {
            margin: 20px 0;
            @media (min-width: $medium-breakpoint) {
                margin: 0 0 30px;
            }
            .event-attend-button  {
                font-size: $base-font-size;
                background-color: $profile-color2;
                padding: 0.8em 2em;
                margin-bottom: 10px;
                display: inline-block;
                color: white;
                border:  thin solid darken($profile-color2, 5%);
                border-radius: 5px;
                cursor: pointer;
                transition: font-size .5s ease;
                margin-top: 1em;

                &:hover {
                    background-color: lighten($profile-color1, 10%);
                }
            }

            .event-signup-form-button {
                font-size: 1em;
                background-color: $profile-color2;
                color: white;
                display: inline-block;
                padding: 15px;
                margin: 10px 0;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color .3s;
                &:hover,
                &--open {
                    background-color: gray;
                }
            }

            .event-signup-form {
                background-color: #f5f5f5;
                transition: max-height .5s linear;
                padding: 10px;
                margin: 0;
                width: 304px;
                box-sizing: content-box;

                a {
                  font-weight: bold;
                }

                &__label {
                    font-size: 1em;
                    line-height: 2em;
                }
                &__textbox {
                    display: block;
                    line-height: 1.2em;
                    padding: 0.4em;
                    width: 100%;
                    font-size: 1em;
                }
                &__recaptcha {
                    margin: 1em auto;
                }
                &--hidden {
                    display:none;
                }
                .field-validation-error,
                .validation-summary-errors {
                    color: red;
                }

                .event-signup-form__success {
                  color: #3a3;
                  margin: 1em 0;
                  font-weight: bold;
                }
            }

        &__headline {
            font-size: 1.2rem;
            font-weight:bold;
            margin-top:0;
            margin-bottom: 10px;
        }
            ul {
              margin:0;
              padding:0;
            }
            ul li{
                list-style-type: none;
            }
        }
      }
        .event-infobox {
            padding: 1em 1em;
            border-bottom: 0.1em solid #0f7900;
            border-top: 0.1em solid #0f7900;
            margin: 1em 0;

            &__header {
                display: inline-block;
                font-weight: 700;
                font-size: $base-font-size * 1.1;
            }

            &__info {
                margin-left: 1em;
            }

            &__date {

            }

            &__time {
                background: url()
            }
        }
    }
}

