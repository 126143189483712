.sidebar {
  @media (min-width: $medium-breakpoint) {
    margin-left:30px;
  }
}

.related-files, .related-links {
  margin-bottom: 2em;
  min-width: 250px;

  h5 {
    margin-bottom: 0.4em;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    border-bottom: 1px dotted;
    margin: 5px 0;
  }
}
