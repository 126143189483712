.footer {
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }

  background-color: $footer-bg-color;
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
  p, a {
    color: $alt-font-color;
    font-size: $footer-font-size;
    font-family: $footer-font-family;
    text-decoration: none;
    margin: 4px 0;
  }

  a:hover, a:active {
    text-decoration: underline;
  }

  &__main {
    width: 100%;
    margin: 0 auto;
    padding: 2em 20px;
    display: flex;
    justify-content:space-between;
    min-height: 150px;
    @media (max-width: $medium-breakpoint) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__menu {
      width:25%;
      @media (max-width: $small-breakpoint) {
        width: 100%;
      }
      a {
        display: block;
        padding: 5px 0;
        text-decoration: none;
      }
    }

    &__newsletter {
      width: 40%;

      .contourPage {
        display: flex;
        align-items: stretch;
        width: 100%;
        justify-content: center;

        * {
          padding: 0 !important;
          margin: 0 !important;
          flex: 0 0 auto;
        }

        .contourFieldSet {
          flex-basis: 10px;
          flex-grow: 100;
          max-width: 400px;
        }

        .contourNavigation div {
          height: 100%;
        }

        .btn {
          font-size: 1em;
          height: 100%;
          padding: 0 0.5em !important;
        }
      }

      // MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
      #mc_embed_signup form {text-align:left; padding:10px 0 10px 0;}
      .mc-field-group { display: inline-block; } /* positions input field horizontally */
      #mc_embed_signup input.email {font-size: 15px; border: 1px solid #ABB0B2;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; color: #00427E; background-color: #fff; box-sizing:border-box; height:32px; padding: 0px 0.4em; display: inline-block; margin: 0; width:350px; vertical-align:top;}
      #mc_embed_signup label {display:block; font-size:16px; padding-bottom:10px; font-weight:bold;}
      #mc_embed_signup .clear {display: inline-block;} /* positions button horizontally in line with input */
      #mc_embed_signup .button {font-size: 13px; border: none; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; letter-spacing: .03em; color: #fff; background-color: lighten($profile-color1, 10%); box-sizing:border-box; height:32px; line-height:32px; padding:0 18px; display: inline-block; margin: 0; transition: all 0.23s ease-in-out 0s;}
      #mc_embed_signup .button:hover {background-color:#0077E4; cursor:pointer;}
      #mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%;margin: 0 5%; clear: both;}
      #mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
      #mc_embed_signup #mce-error-response {display:none;}
      #mc_embed_signup #mce-success-response {color:#529214; display:none;}
      #mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}
      @media (max-width: 768px) {
          #mc_embed_signup input.email {width:100%; margin-bottom:5px;}
          #mc_embed_signup .clear {display: block; width: 100% }
          #mc_embed_signup .button {width: 100%; margin:0; }
      }
      // Mailchimp end

      @media (max-width: $medium-breakpoint) {
        width: 100%;
      }
    }

    &__contact-info {
      width: auto;
      @media (max-width: $medium-breakpoint) {
        width: 100%;
      }
      p {
        line-height: 1.2em;
      }
      h3 {
        font-size: $footer-font-size * 1.3;
      }
    }
  }

  &__copyright {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0.5em 0;
    background-color: $footer-bottom-bg-color;

    @media (max-width: $medium-breakpoint) {
      display: flex;
      justify-content: center;
    }

    &__text {
      display: flex;
      justify-content:space-between;
      font-family: $footer-font-family;
      color: $alt-font-color;
      @media (max-width: $medium-breakpoint) {
        display:flex;
        flex-direction: column;
        text-align: center;

        & > span {
          margin: 2% 0;
        }
      }
    }
  }
  &__item {
    line-height: 1.5em;
    span {
      font-size:20px;
      vertical-align:middle;
    }
  }

}

.box {
  flex-direction: column;
  //margin: 5px 40px 5px;
  color: $alt-font-color;
  @media (max-width: $medium-breakpoint) {
    padding: 10px ;
    &.no-content {
      display: none;
    }
    h4 {
      margin: 2%;
    }
  }
}


