.event-container {
  $padding: 0.625rem;
  $smallWidth: 32.4%;
  $mediumWidth: 48%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $small-breakpoint) and (max-width: $large-breakpoint) {
    & > div:nth-child(2n) {
      margin-left: 4%;
      margin-bottom: 40px;
    }
  }
  @media (min-width: $large-breakpoint) {
    & > div:nth-child(3n + 2) {
      margin: 0 1.4%;
      margin-bottom: 40px;
    }
  }
  &__eventPageItem {
    border-radius: 3px;
    background-color: #fff; //temp
    min-width: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;

    @media (min-width: $small-breakpoint) {
      width: $mediumWidth;
    }
    @media (min-width: $large-breakpoint) {
      width: $smallWidth;
    }
    &__image {
      display: block;
      margin-bottom: 10px;
    }
    &__text {
      border-radius: 3px;
      background-color: #fff; //temp
      margin: 0;
      &__title {
        display: flex;
        flex-direction: column;
        color: black;
        margin-bottom: 20px;
      }
      &__container {
        display: flex;
        color: $alt-font-color;
        &__date {
          width: 60px;
          height: 70px;
          display: inline-block;
          vertical-align: top;
          background-color: $primary-company-color2;
          color: $alt-font-color;
          padding: 5px;
          text-align: center;
          border-right: 1px solid white;
          &__day {
            font-size: 2rem;
            font-weight: bold;
            padding: 0;
          }
          &__month {
            font-size: 1.1rem;
            font-weight: 300;
          }
        }
      }
      &__bottomLink {
        background-color: $profile-color1;
        height: 30px;
        display: flex;
        justify-content: space-around;
        color: white;
        align-items: center;
        margin-top: 25px;
      }
    }

    h3 {
      margin: 0px 10px 10px;
      font-size: 1.17em;
    }

    p {
      margin: 0px 10px;
      line-height: 1.2;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__toggle-button {
    background-color: $primary-company-color2;
    border: none;
    font-size: 13px;
    border-radius: 3px;
    letter-spacing: 0.03em;
    color: #fff;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    display: inline-block;
    margin: 0;
    transition: all 0.23s ease-in-out;
    width: $smallWidth;

    @media (max-width: $large-breakpoint) {
      width: $mediumWidth;
    }

    @media (max-width: $small-breakpoint) {
      width: 100%;
      height: 60px;
      font-size: 16px;
    }
  }
}
