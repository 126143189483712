$section-margin: 100px;
$base-content-margin: 40px;

$icon-text: $base-font-size - 0.13;
$header-height: 60px;
$header-border-width: 4px;
$icon-window-size: 120px;
$icon-window-size-tablet: $header-height;
$icon-window-size-mobile: 40px;

