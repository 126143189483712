.member-news {
    display:flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    @media (min-width: $medium-breakpoint) {
        flex-direction:row;
        text-align: unset;
    }
    &__container {
        margin: 20px 0;
        width: 100%;
        padding: 0 30px;
        @media (min-width: $medium-breakpoint) {
          width:33%;      
        }
    }
    &__container:nth-child(2) {
        @media (min-width: $medium-breakpoint) {
            border-right: 1px solid black;
            border-left: 1px solid black;
        }
    }
}

