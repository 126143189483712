.row{
  box-sizing: border-box;
  flex-direction: row;
  flex: 0 1 auto;
  flex-wrap: wrap;
  display: flex;
}
.grid-item{
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 0 10px;

  @media screen and (min-width: $small-breakpoint + 1) and (max-width: $medium-breakpoint) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: $medium-breakpoint + 1) and (max-width: $large-breakpoint) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: $large-breakpoint + 1) {
    flex-basis: 25%;
    max-width: 25%;
  }
}
