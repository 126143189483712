.edit-member-page {
	.edit-form {
        &__row {
            margin-bottom: 10px;
        }

        &__label {
            font-size: 1em;
            width: 100px;
            margin-right: 5px;
            display:inline-block;
            text-align:left;
        }

        &__textbox {
            display: inline-block;
            line-height: 1.2em;
            padding: 0.4em;
            width: 300px;
            font-size: 1em;
        }

        &__button {
            font-size: $base-font-size;
            border-radius: 3px;
            padding: 10px;
            margin-top: 10px;
            cursor: pointer;
            transition: background-color .5s ease;
            background-color: $base-button-color;
            border: none;            

            &:hover {
                background-color: $base-button-color-hover;
            }
        }


        &__message {
            font-size: 0.8em;
            
            &--error {
                color: $error-color;
                display:block;    
            }

            &--hidden {
                display: none;
            }
        }

	}
}