.pagination {
  padding-top: 20px;
  font-family: $nav-font-family;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: inline-block;
  font-size: 0;

  display: flex;
  flex-wrap: wrap;

  &__button {
    display: inline;
    margin: 0;
    margin-right: -1px;
    position: relative;

    span, a {
      padding: 6px 12px;
      border: thin solid #ddd;
      background: #fff;
      color: $navbar-font-active-color;
      font-size: 1rem;
      float:left;

      &:hover {
        background: #f4f4f4;
      }
    }

    span {
      color: inherit;
    }

    &--active {
      z-index: 1;
      span, a {
        background: $navbar-font-active-color;
        border-color: $navbar-font-active-color;
        color: #fff;
        pointer-events: none;

        &:hover {
          background: $navbar-font-active-color;
        }
      }
    }
  }
}