.banner{
  width: 100%;
  margin-bottom: $section-margin / 2;
  @media screen and (min-width: $medium-breakpoint + 1){
    margin-bottom: $section-margin;
  }
  img{
    width: 100%;
    //min-height: 200px;
  }
  &:hover{
    .slick-arrow{
      display: block;
    }
  }
  .slick-arrow{
    display: none;
    width: auto;
    height: auto;
    z-index: 1;
    &:before{
      font-size: 32px;
    }
  }
  .slick-next{
    right: 25px;
  }
  .slick-prev{
    left: 15px;
  }

  &__slide{
    position: relative;
    width: 100%;
    height: 260px;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: $small-breakpoint + 1){
      height: 300px;
    }
    @media screen and (min-width: $medium-breakpoint + 1){
      height: 480px;
    }
    @media screen and (min-width: $large-breakpoint + 1){
      height: 580px;
    }
    &__content-wrapper{
      position: absolute;
      background-color: rgba(0,0,0,0.6);
      text-align: center;

      width: 100%;
      top: 50%;
      transform: translateY(-50%);

    }
    &__content{
      display: block;
      overflow: hidden;
      @media screen and (min-width: $small-breakpoint + 1){
        display: block;
      }
      &__title{
        color: $alt-font-color;
        padding: 0 40px;
        @media screen and (max-width: $small-breakpoint){
          font-size: 22px;
          margin-top: 6px;
          margin-bottom: 4px;
        }
      }
      &__desc{
        color: $alt-font-color;
        padding: 0 40px;

        h1 {
          font-size: 20px;
        }

        p {
          color: inherit;
          @media screen and (max-width: $small-breakpoint){
            font-size: 12px;
            margin: 0 0;
          }
        }
      }
      &__link{
        margin: 10px 0;
        @media screen and (max-width: $medium-breakpoint) {
          margin: 6px 0;
        }
      }
    }
  }
}
