.frontpage {

    &__banner {
        h1, h2, h3, h4, h5, h6 {
          color: #ccc;
        }
         &__container {
            display:flex;
            flex-direction: row;
            background:rgba(0,0,0, .4);
            padding: 0 20px;
            @media (max-width: $large-breakpoint) {
                display:flex;
                flex-direction: column;
            }
            &__maintext {
                font-family: $base-font-family;
                padding: 10px 0;
                margin: 10px 0;
                color: white;
                text-align:center;
                max-width: 69%;
                p {
                    color: white;
                }
                @media (max-width: $large-breakpoint) {
                    text-align: center;
                    max-width:100%;
                }
            }
            &__membertext {
                max-width: 31%;
                text-align: center;
                border-left: 1px solid;
                border-color: white;
                padding: 10px 0 0 30px;
                margin: 10px;
                color: white;
                @media (max-width: $large-breakpoint) {
                    border-left: none;
                    border-top: 1px solid;
                    text-align: center;
                    margin: 0 0 20px;
                    padding: 0;
                    max-width:100%;
                }
                 p {
                    color: white;
                    font-size: $base-font-size+ 0.188em;
                }

                h3 {
                    @media (max-width: $large-breakpoint){
                        margin: 10px 0 0;
                    }
                }
                h5 {
                    text-align: center;
                    padding: 10px ;
                    margin: 0;
                    color: black;
                    & > span {
                        background: white;
                        padding: 10px 25px;
                    }
                }
            }
        }

    }

    &__content {
        display:flex;

        @media (max-width: $small-breakpoint) {
            flex-direction:column-reverse;
        }
        &__middle {
            flex: 1;
            margin: 10px 20px 0 0 ;
            @media (max-width: $small-breakpoint) {
                margin-top: 30px;
            }
            @media (max-width: $desktop-breakpoint) {
                margin: 10px 20px 0 0;
            }

        }
        &__right {
            margin: 10px 0 0 0;
            width: 280px;
            @media (max-width: $small-breakpoint) {
                width:100%;
            }
        }
    }
    &__advertising {
        background-color: $header-bg-color;

        &__container {
            display: flex;
            flex-direction: row;
            padding-bottom: 20px;
            justify-content: space-around;
            flex-wrap: wrap;
            @media (max-width: $large-breakpoint) {
                flex-direction: column;
                align-items:center;
            }
            & > div {
                padding: 5px;
            }
        }

    }
    &__member-news {
        margin-top: 2em;
    }
    &__partners {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 2em;
        background: #fff;

        &__container {

          &__content {
            display: flex;
            flex-direction: row;
            @media (max-width: $large-breakpoint) {
            flex-direction:column;
            }
         }
       }
   }

}