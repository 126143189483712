%btn{
  outline: none;
  padding: 10px 15px;
  border: 1px solid $link-color;
  color: $base-btn-color;
  background-color: $link-color;
  font-size: 1.2em;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s 0s linear;
  &:hover, &:active, &:focus{
    color: $base-btn-color;
    background-color: darken($link-color, 10%);
    border-color: darken($link-color, 10%);
  }
  &--bare-bones{
    color: $link-color;
    background-color: $base-btn-color;
    &:hover, &:active, &:focus{
      color: $base-btn-color;
      background-color:$link-color;
      border-color: $link-color;
    }
  }
}
%alt-btn{
  outline: none;
  padding: 10px 15px;
  border: 1px solid $base-font-color;
  color: $base-font-color;
  background-color: transparent;
  font-size: 1.2em;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s 0s linear;
  &:hover, &:active, &:focus{
    color: $link-color;
    border-color: $link-color;
  }
}
.article-btn{
  @extend %alt-btn;
  display: inline-block;
  align-self: center;
  margin-bottom: 25px;
}
.link-btn{
  @extend %btn;

  &--small{
    padding: 7px 15px;
    font-size: 1em;
  }
  &--big{
    @media screen and (min-width: $medium-breakpoint + 1){
        padding: 15px 20px;
        font-size: 1.4em;
    }
  }

  @media screen and (max-width: $medium-breakpoint){
    padding: 6px 10px;
    margin: 10px 0;
    font-size: 1em;
  }

  &--bare-bones{
  @extend %btn--bare-bones;
  }
}
