.search-results {
    $typeDisplayNameWidth: 80px;

    &__filters {
      background-color: #f4f4f4;
      border: 1px solid #e5e5e5;
      padding: 5px 10px;
      margin-top: 1.5em;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &__label {
        margin-right: 1em;
        font-weight: bold;
        white-space: nowrap;
      }
    }

    &__type-buttons{
      &__btn {
        background: #fff;
        padding: 5px 10px;
        margin-right: 5px;
        border: 2px solid #e5e5e5;
        border-radius: 2em;
        cursor: pointer;
        transform: translateZ(0);
        text-transform: uppercase;
        transition: border 0.2s, color 0.2s;

        &--active{
          border-color: $profile-color2;
        }

        &:hover {
          border-color: $profile-color2;
          color: $profile-color2;
        }

        &:nth-child(1){
          margin-left: 0;
        }
      }
    }


    &__title{
        margin: 15px 0;
        font-size: 18px;
        line-height: 34px;
    }

    &__search-word {
      display: block;
      font-size: 2.5em;
      font-weight: bold;
    }


    &__results {
      display:flex;
      flex-direction:row;
      flex-wrap: wrap;
      margin: 0;

      .search-result{
          border-bottom: 1px solid $base-border-color;
          padding: 30px;
          position: relative;

          display: flex;
          flex-direction: row;
          flex-basis: 100%;
          justify-content: space-between;
          flex-wrap: nowrap;

          &:hover {
            background-color: rgba(255, 205, 0, 0.1);
            border-bottom-color: rgba(255, 205, 0, 0.1);
          }

          &__highlightText {
            background-color: $highlight-color;
          }

          &__image {
            flex-basis: 20%;
            @media (max-width: $medium-breakpoint) {
                display: none;
            }

          }
          &__title{
              max-width: calc(100% - #{$typeDisplayNameWidth + 10});
              margin: 0;
              font-size: 1.3em;
          }
          &__link {
              color: $link-color;
              margin: 3px 0 10px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              font-size: 0;
            }
          &__content {
            flex-basis: 100%;
            display:flex;
            flex-direction: column;
            & > p {
            margin: 4px 0;
            }
          }
          &__date {
            display: block;
            font-size: 0.8em;
            color: #3a3a39;
            margin-left: 0.0em;
          }
          &__typeDisplayName {
              background-color: $profile-color3;
              color: $profile-color3-contrast;
              padding: 0.25em;
              position: absolute;
              text-align: center;
              width: $typeDisplayNameWidth;
              top:30px;
              right:30px;

          }

      }

      &__return {
          border-radius: 1%;
          padding: 1%;
          margin: 2% 0;
        }
        &__return:hover {
            background: #f0f3f4;
        }
     }

}
