.article-container {
  //margin-top: $base-content-margin;

  &__container {
      &__top-content {
          &__title {
            border-bottom: 2px #dcdcdc solid;
            margin-top: 3px;
          }
          &__description {
            font-weight:bold;
            margin-bottom: 1em;
            white-space: pre-line;
          }
      }
  }
}
