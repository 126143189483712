.register-member-page {
	.register-form, .register-company-form {
        width: 300px;

        &__label {
            font-size: 1em;
            line-height: 2em;
        }

        &__textbox {
            display: block;
            line-height: 1.2em;
            padding: 0.4em;
            width: 100%;
            font-size: 1em;
        }

        &__button {
            font-size: $base-font-size;
            border-radius: 3px;
            padding: 10px;
            margin-top: 10px;
            cursor: pointer;
            transition: background-color .5s ease;
            background-color: $base-button-color;
            border: none;
            width: 100%;

            &:hover {
                background-color: $base-button-color-hover;
            }
        }

        &__recaptcha {
            margin-top: 15px;
        }

        &__message {
            font-size: 0.8em;

            &--error {
                color: $error-color;
            }

            &--hidden {
                display: none;
            }
        }

	}

  #find-company-form {
    display: flex;
    align-items: stretch;
    input {
      padding-left: 0.5em;
      flex: 1 0 auto;
    }

    button {
      min-width: 3em;
      position: relative;
      span::before {
        font-size: 1.5em;
        vertical-align: middle;
      }
    }
  }
}