.contact-page {
   &__container {
       &__keyinfo {
           list-style: none; 
           margin: 10px 0;
       }
       &__contactinfo {
           margin: 20px 0 0;
           padding: 0;
       }
   }
}

