.article-card{
  box-shadow: $base-box-shadow;
  width: 100%;
  text-decoration: none;
  transition: all 0.2s 0s linear;
  &:hover{
    box-shadow: $hover-box-shadow;
    .article-card__content__title{
      color: $link-color;
    }
    .article-card__content__link{
      border-color: $link-color;
      color: $link-color;
    }
  }
  &__content{
    padding: 0 30px 20px;
    flex-grow: 1;
    &__title{
      transition: all 0.2s 0s linear;
      color: $base-heading-color;
    }
    &__text{
      margin-bottom: 10px;
      color: $base-font-color;
    }
  }
}
