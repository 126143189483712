$searchbox-width: 100%;
$searchbox-width-desktop: 300px;
$searchbox-btn-width: 30px;
.searchbox{
  width: $searchbox-width;
  position: fixed;
  top: $header-height + 5px;
  left: 0;
  background-color: #FFFFFF;
  padding: 3px;
  box-shadow: $base-box-shadow;

  &__controls{
    display: inline-block;
    width: calc(#{$searchbox-width} - #{$searchbox-btn-width} - 10px);

    &__input{
      font-size: 18px;
      padding: 3px;
      height: $searchbox-btn-width;
      width: 100%;
    }
  }
  &__btn{
    height: $searchbox-btn-width;
    width: $searchbox-btn-width;
    vertical-align: top;
  }
  @media (min-width: $menu-breakpoint){
    position: relative;
    top:0;
    left: 0;
    background-color: transparent;
    box-shadow: none;
    width: $searchbox-width-desktop;
    &__controls{
      width: calc(#{$searchbox-width-desktop} - #{$searchbox-btn-width} - 10px);
    }
  }
}
