
.main-navigation {
  padding: 0 0;
  width: 100%;

  a {
    color: $base-font-color;
  }

  @media (min-width: $menu-breakpoint + 1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #menu-button {
    margin-left: auto;
    margin-right: 6px;
    @media (min-width: $desktop-breakpoint + 1) {
      display: none;
    }
  }

  //Main buttons
  ul > li > a {
    transition: background-color .3s;
    font-size: $nav-font-size+0.125em; //18px
    width: 92%;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    @media (min-width: $menu-breakpoint + 1) {
      display: block;
      padding: 20px 0px 10px 5px;
      width:100%;
      border-bottom: 1px solid;
    }
    padding-right: 0;
  }
 //Main buttons @ holding over
  ul > li > a:hover {
    background-color: #e9e9e9;
  }

  @media (min-width: $menu-breakpoint + 1) {
    ul > li {
      position: relative;
  }

    ul > li > ul {
      width: auto;
      padding: 0;
    }
//When holding over main buttons show sub buttons
 /*   ul > li:hover > ul {
      display: block;
      z-index: 100;
      background-color: #f9f9f9;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      @media(min-width: $desktop-breakpoint) { //Remove buttons in desktop-breakpoint
          display:none;
      }
      li {
        padding: 0;
      }
    }*/

    ul > li:last-child > ul {
      left: -100%;
      right: 0%;
    }
//Size of sub menu buttons
    ul > li > ul > li {
      width: 100%;
    }
//Background color of sub menu buttons
    ul > li > ul > li:hover {
      background-color: #f1f1f1;
    }
//Link function of sub menu buttons
    ul > li > ul > li > a {
      display: inline-block;;
      height: 100%;
      width: 100%;
      padding-left: 20px;
    }

    ul > li:hover > ul > li {
    }
  }

  .navigation-list {
    z-index: 10;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    //background-color: $header-bg-color;

    @media (max-width: $menu-breakpoint) {
      background-color: $primary-bg-color;
      box-shadow: 0px 2px 5px #888888;
// Button to get sub menu
      .angle {
        display: inline-block;
        margin: 5px;
        border-top: 3px inset;
        border-left: 3px inset;
        transform: rotate(135deg);
        transition: transform 0.15s 0s ease;
        width: 15px;
        height: 15px;
        &.active {
          transform: rotate(225deg);
        }
      }
    }

    @media (min-width: $menu-breakpoint + 1) {
      float: right;
    }

    &__item {
      position: relative;
      min-height: 50px;
      flex-wrap: wrap;
      align-items: center;
      @media (min-width: $menu-breakpoint + 1) {
        display: inline-block;
        border: none;
        padding: 0;
      }

      &.active > a {
        color: $navbar-font-active-color;
      }

      &.open {
        padding-bottom: 0;
      }
    }

    @media (max-width: $menu-breakpoint) {
      background-color: $header-bg-color;

      &--show {
        display: block;
      }
    }
  }
}

.sub-navigation-list {
  display: none;
  padding-left: 15px;

  &.open {
    display: block;
    padding-top: 5px;

    position: relative;
    top: 0;

    // height: 100%;
    width: 100%;

    .navigation-list__item {
      padding-left: 0;
      &:first-of-type {
        border-top: none;
      }
    }
  }
}