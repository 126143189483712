$base-font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
$header-font-family: $base-font-family;
$footer-font-family: $base-font-family;
$nav-font-family: $base-font-family;

$base-font-size: 1em;
$footer-font-size: $base-font-size;
$nav-font-size: $base-font-size;

h1, h2, h3, h4, h5, h6 {
  color: $base-font-color;

  a {
    color: $base-font-color;
  }
}

h1 {
  font-family: $header-font-family;
  font-size: $base-font-size * 2.125;
  line-height: 32px;
  font-weight: normal;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 2.625;
    line-height: 48px;
  }
}


h2 {
  font-family: $header-font-family;
  font-size: $base-font-size * 1.6;
  line-height: 24px;
  font-weight:normal;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 2;
    line-height: 32px;
  }
}

h2.news-first-h {
  font-size: $base-font-size* 2.250;
}


//Under headings
h3 {
  font-family: $header-font-family;
  font-size: $base-font-size * 1.5;
  font-weight: normal;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 1.25;
  }
}

h4 {
  font-family: $header-font-family;
  font-size: $base-font-size * 1.4;
  font-weight: normal;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 1.6;
  }
}

h4.footer-headline {
    font-size: $base-font-size+0.188em;
}

h5 {
  font-family: $header-font-family;
  font-size: $base-font-size * 1.3;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 1.3;
  }
}

.striped {
  font-size: $base-font-size+0.125; //18px
  margin:0;
  color: $alt-font-color;
  border-bottom: 2px solid $profile-color1;
  font-weight:normal;
  text-transform: uppercase;
    >span{
      display: inline-block;
      background-color: $profile-color1;
      padding: 3px 10px;
    }
}

h6 {
  font-family: $header-font-family;
  color: $base-heading-color;
  font-size: $base-font-size * 1.4;
  @media screen and(min-width: $medium-breakpoint + 1){
    font-size: $base-font-size * 1.6;
  }
}

p {
  color: $base-font-color;
  line-height: $base-font-size * 1.6;
  margin: 5px 0 10px;
}

p.news-first-p {
    font-size: $base-font-size *1.25;
}

p.banner-frontpage {
    font-size: $base-font-size+ 0.125em;
}

a {
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $link-color;
  text-decoration: none;
  &:hover, &:active, &:focus{
    color: $link-color-active;
    // text-decoration: underline;
  }
}

body{
  font-family: $base-font-family;
  color: $base-font-color;
  background-color: $primary-bg-color;
}

.created-date {
    color: gray;
    font-size:14px;
    font-style:italic;
    display: inline-block;
    margin: 3px 0 3px;
}

.error,.warn,.success,.info{
  margin: 10px 0px;
  padding:12px;
}
.error{
  color: #D8000C;
  background-color: #FFBABA;
}
.success{
  color: #4F8A10;
  background-color: #DFF2BF;
}
.warn{
  color: #9F6000;
  background-color: #FEEFB3;
}
.info{
  color: #00529B;
  background-color: #BDE5F8;
}
