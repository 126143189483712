.partners-page {
  margin-top: $base-content-margin;
  &__title {
    border-bottom: 1px #dcdcdc solid;
    margin-top: 0;
  }

  &__logos-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    &__logo {
      margin-bottom: 1.5em;
      flex-basis: 20%;
      max-width: 20%;
    }
  }
}
