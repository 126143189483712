.upcoming-events{
  font-size: 10px;

  .upcoming-event{
    $date-width: 3.8rem;
    $padding: 0.625rem;

    border: 1px solid $base-border-color;
    border-bottom: none;
    padding: $padding;
    cursor: pointer;

    &:hover{
      background-color: lighten($primary-bg-color, 25%);
    }
    &:last-of-type{
      border-bottom: 1px solid $base-border-color;
    }
    &__image {
        margin-bottom:15px;
        width: 100%;
        &__img {
            width:100%;
        }
    }
    &__date{
      width: $date-width;
      display: inline-block;
      vertical-align: top;
      background-color: $primary-company-color2;
      color: $alt-font-color;
      padding: 5px;
      text-align: center;
      margin: 5px 0 0;
      &__day{
        font-size: 2rem;
        font-weight: bold;
      }
      &__month{
        font-size: 1rem;
        text-transform: uppercase;
        font-style:normal;
      }
    }
    &__content{
      width: calc(100% - #{$date-width} - #{2*$padding});
      padding: 0 $padding;
      display: inline-block;
      vertical-align: top;
      margin: 5px 0 0;
      > div {
          font-size:13px;
      }
      &__title{
        h1{
          margin: 0;
          margin-bottom: 5px;
          font-size: 0.938rem;
          line-height: 1.1rem;
          font-weight: bold;
        }
      }
    }
  }
}
