.featured-articles{
  margin-bottom: $section-margin;
  padding: 0 15px;
  @media screen and (min-width: $medium-breakpoint + 1){
    padding: 0 20px;
  }
  @media screen and (min-width: $max-width + 1){
    padding: 0;
  }
  .article-card{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
}
