

.contour {
    font-family: $footer-font-family;
    
    .btn {
      border-radius: 1px;
      font-family: $footer-font-family;
      font-size: 30px;
      -webkit-box-shadow: 1px 3px 1px 0px rgba(0,0,0,0.25);
      padding: 2px 15px;
      margin: 0 0 20px;
    }
    .text {
        background-color: white;
        padding: 10px;
        width: 100%;
    }
    input[type=text] {
        padding:10px !important;
    }
    .contourField.shortanswer {
        padding: 10px 0 0;
    }
    .contourFieldSet {
        padding: 0 25px 0 0;
    }
    .span12 {
        padding: 0;
        @media (min-width: $large-breakpoint) {
        }
    }
    .col-md-12 {
        padding: 0;
    }
    
} 
.searchbox {
    @media (max-width: $large-breakpoint) {
        top: 62px;
        padding: 10px;
        }
    }



 


