.social-links {
    padding: 10px 0;
    display:flex;
    flex-direction: row;
    border-bottom: 1px dotted;
    border-top: 1px dotted;       
    &__item {
        padding: 0 20px;
        line-height: 1.5em;
        span {
            font-size:20px;
            vertical-align:middle;
        } 
   }
}


