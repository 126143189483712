
.header--hidden-scroll:not(.menu-button--show){
  .header{
    top: -#{$header-height};
  }
}

.padding-for-header {
  padding-top: $header-height;
}

.header {
  width: 100%;
  position: fixed;
  background-color: $header-bg-color;
  border-bottom: $header-border-width solid $header-border-color;
  z-index:10;
  transition: $default-transition;
  top:0;
  left: 0;

  &__logo {
    height: 100%;
    height: 60px;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    img {
      max-height: 100%;
      padding: 6px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $desktop-breakpoint;
    width: 100%;
    &__menu {
      display: flex;
      height: $header-height;
      &__search, &__member {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border: 1px solid $header-border-color;
        border-width: 0 1px 0 1px;
        width: $icon-window-size-mobile;
        @media (min-width: $mobile-breakpoint) {
          width: $icon-window-size-tablet;
        }
        &__icon{
          font-size: 24px;
          margin-top: 5px;
          @media(max-width: $mobile-breakpoint) {
            font-size: 20px;
          }
        }
        &__text{
          display: none;
          font-size: $icon-text;
        }

        @media (min-width: $menu-breakpoint+1)     {
          width: $icon-window-size;
          &__text {
            display: block;
          }
          &__icon{
            font-size: inherit;
            margin-top: 0;
          }
        }
      }

      &__search-window {
        .searchbox {
          padding: 15px;
          // box-shadow: inset 4px 4px 16px -10px rgba(0, 0, 0, 0.75);
          width: 100%;
          transition: width 0.3s;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;
          float: right;

          @media (max-width: $large-breakpoint) {
            top: 64px;
          }

        }

        &.hidden {
          display: block !important;
          opacity: 0;

          .searchbox {
            width: 0;
          }
        }
      }
      &__hamburger {
        margin: 10px 0 10px 10px;
        @media(min-width: $menu-breakpoint + 1) {
          display:none;
        }
      }
    }
  }
  &__member-dropdown {
    width:100%;
    margin: 0;
    padding: 15px 20px;
    position:absolute;
    right: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    background-color: #F5F5F5;
    margin-top: $header-border-width;
    z-index:1;

    @media (min-width: $desktop-breakpoint + 1) {
      width: 1190px;
      margin-right: 31px;
      border-radius: 0px 0px 3px 3px;
    }

    &__checkbox {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    &__checkbox-text {
      display:inline-block;
    }

    &__error-message {
      color:red;
      ul {padding: 0; margin: 10px 0 0 0;}
      li {list-style-type: none;}
    }
  }
  &__hamburger-dropdown {
    background-color: white;
    position: absolute;
    right:0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    padding: 0 40px 40px;
    display:none;
  }
}
.show-header__member-dropdown{
  .header__member-dropdown{
    display: block;
  }


  .login {
    &__links  {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      a {
        white-space: nowrap;
      }

      span {
        margin-top: 0.5em;
      }
    }

    &--logged-in {
      display: flex;
      flex-direction:column;
      align-items:center;

      &__item {
        padding: 10px;
        display: block;
      }

      &__item--link {
        cursor: pointer;
        border-radius: 5px;
        transition: background-color linear .3s;
        &:hover {
          color: $link-color;
          background-color: $link-bg-color;
        }
      }

      @media(min-width: $menu-breakpoint + 1) {
        flex-direction: row;
        justify-content: flex-end;

        &__item--left {
          margin-right: auto;
        }
      }
    }

    &__flex {
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 1em;
      line-height: 2em;
    }

    &__textbox {
      line-height: 1.2em;
      padding: 0.4em;
      width: 100%;
      font-size: 1em;
    }

    &__button {
      font-size: $base-font-size;
      border-radius: 3px;
      padding: 10px;
      margin-top: 10px;
      cursor: pointer;
      transition: background-color .5s ease;
      background-color: $base-button-color;
      border: none;
      width: 100%;

      &:hover {
        background-color: $base-button-color-hover;
      }
    }

    &__message {
      &--error {
        text-align:center;
      }
    }

    @media(min-width: $medium-breakpoint + 1)
    {
      &__flex {
        flex-direction: row;
      }

      &__label {
        display:none;
      }

      &__textbox {
        margin-right: 10px;
        flex-basis: 0 auto;
      }

      &__button {
        margin: 0;
        width:200px;
      }
    }
  }
}
