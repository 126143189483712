$sidebar-margin: 40px;
.container{
  max-width: $desktop-breakpoint;
  padding:0 30px;
  width: 100%;
  position:relative;
  padding: 0 10px;
  @media (min-width: $medium-breakpoint) {
      padding: 0 20px;
  }
  
}
img{
  max-width: 100%;
  border: none;
}

.content-block {
	max-width: $max-width;
	margin: 0 auto;
    padding: 0 15px;
    @media (min-width: $medium-breakpoint + 1){
        padding: 0 20px;
    }
    @media (min-width: $max-width + 1){
        padding: 0;
    }
  &--with-sidebar{
    @media (min-width: $large-breakpoint + 1){
      display: flex;
    }
  }
  &__content{
    width: 100%;
    @media screen and (min-width: $large-breakpoint + 1){
      flex: 0 1 75%;
    }
  }
  &__sidebar{
    flex: 0 1 25%;
    display: none;
    @media screen and (min-width: $large-breakpoint + 1){
      display: block;
    }
    margin-right: $sidebar-margin;
    &__list{
      list-style: none;

      a {
        font-size: 1.4em;
      }
    }
  }
}
html {
  height: 100%;
  box-sizing: border-box;
  font-size: 100%;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#main {
  flex: 1;
}

* {
  box-sizing: border-box;
}
