.card-container {
  display: flex;
  flex-direction:column;
  @media (min-width: $small-breakpoint) {
      flex-flow: wrap;
  }
      &__first-card {
        width:100%;
        display:flex;
        flex-direction:column;
        margin-bottom:30px;
        @media (min-width: $small-breakpoint) {
            flex:none;
        }
        &__card-title {
            font-size: 2.25em;
            margin: 0 0 10px;
        }
        &__card-ingress {
            font-size: 1.25em;
        }
      }
}

.card-block {
    &__content {
        display:flex;
        flex-direction: column;
        &__card-title {
            margin: 0 0 10px;
            padding: 5px 0 0px;
            //text-align:center;
        }
        &__card-image {
            display:block;
            margin: auto;
        }
    }
}

.single-card-container {
 margin-bottom: 30px;
 margin-left: 0px;
    @media (min-width: $small-breakpoint) {
        width:48%;       
    }
    
    @media (max-width: $large-breakpoint) and (min-width: $small-breakpoint){
        &:nth-child(2n+2) {
            margin-left: 24px;
        }
    }
    @media (min-width: $large-breakpoint) {
        width:31%;
        margin-left: 26px;
        &:nth-child(3n+1) {
            margin-left: 0px;
       }
    }
}

