$profile-color: #dd5145;
$profile-color1: #00427E; //#00594F;
$profile-color2: #009681;
$profile-color3: #15562C;
$profile-color4: #666666;
$profile-color5: #FFFFFF;
$profile-color6: #1A1E1A;

$profile-color-contrast: #FFFFFF;
$profile-color1-contrast: #FFFFFF;
$profile-color2-contrast: #FFFFFF;
$profile-color3-contrast: #FFFFFF;
$profile-color4-contrast: #FFFFFF;
$profile-color5-contrast: #1A1E1A;
$profile-color6-contrast: #FFFFFF;

$primary-company-color2: lighten($profile-color1, 20%);
$primary-company-color3: #A0D1CA;
$primary-company-color4: #C9E2E0;
$primary-company-color7: #B6B8DC;

$primary-bg-color: #FBFAF8;
$header-bg-color: #F5F5F5;
$footer-bg-color: #363839;
$footer-bottom-bg-color: #282a2b;

$navbar-font-active-color: #FAA31B;
$navbar-font-inactive-color: #34658A;

$base-font-color: $profile-color6;
$base-heading-color: darken($profile-color6, 10%);
$alt-font-color: #FFFFFF;
$content-background: #F5F5F5;


$base-border-color: #ededed;
$header-border-color: $profile-color6;
$base-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
$hover-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
$link-color: #0059CF;
$link-color-active: $profile-color2;
$link-bg-color: #e2e2e2;

$base-btn-color: #FFFFFF;

$heading-label-background-color: #363839;
$heading-label-color: #FFFFFF;

$highlight-color: lighten(desaturate($profile-color2, 50%), 50%);

$base-button-color: #cdcdcd;
$base-button-color-hover: #e2e2e2;

$error-color: #FF0000;