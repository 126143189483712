.featured-news{
    &__news {
      border:solid; border-width: 1px 0 0;border-color:lightgray;
      h3 {
        margin: 0 0 5px;
        padding:0;
      }
      &__first {
        &__image {
          margin: 10px 0 0;
        }
        &__text {
            .news-first-h {
            margin: 0 0 10px;
            padding:0;
            line-height: 1em;
            }
          p {
            padding:0 0 10px;
          }
        }
      }

      &__second {
        margin-bottom: 25px;
        p {
           padding: 0 0 10px;
        }
        .news-headline-all {        //targets second news article 
        margin: 0 0 10px;
        padding:0;
        }
          
      }
      &__all {
          display:flex;
          flex-direction:column;

          &__news {
            margin-bottom: 25px;
            .news-headline-all {
                margin: 0 0 10px;
                padding:0;
              }
          }
          
          @media (min-width: $small-breakpoint) {
            display:flex;
            flex-direction: row;
            
            > div:nth-child(2) {      //gives padding to middle news text
              margin: 0 20px;
            }   
            &__news {     
              width: 33.33%;
            }
          }
      }
    }

}

