$top-margin: 30px;

.layout {
    z-index:1;
    display: flex;
    flex-direction:row;
   &__leftsideMenu{
       width: 20%;
       padding: 0 30px 0 0;
       margin-top: $top-margin;
       z-index: 10;

       @media (max-width: $menu-breakpoint) {
         display: none;
         position:fixed;
         width: 100%;
         margin-top: 0;
         left: 0;
         padding:0;
         top: $header-height;
         transition: $default-transition;

          .navigation-list{
              float: none;
          }
          &.show{
              display: block;
          }
       }
   }
   &__rightsideMenu {
       width: 80%;
       margin-top: $top-margin+5;
       @media (max-width: $menu-breakpoint) {
         width:100%;
       }
   }
}

.header--hidden-scroll:not(.menu-button--show){
  .layout {
    &__leftsideMenu{
      @media (max-width: $menu-breakpoint) {
        top: $header-border-width;
      }
    }
  }
}
