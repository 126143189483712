.reset-password {
    .reset-password-form {
        &__field {
            margin-bottom: 10px;
        }

        &__textbox {
            display: block;
            line-height: 1.2em;
            padding: 0.2em;
            font-size: 1em;
            width: 300px;

            &--error {
                border-color: red;
            }
        }
        
        &__button {
            font-size: $base-font-size;
            border-radius: 3px;
            padding: 10px;
            margin-top: 5px;
            cursor: pointer;
            transition: background-color .5s ease;
            background-color: $base-button-color;
            border: none;

            &:hover {
                background-color: $base-button-color-hover;
            }
        }
    }
    &__message {
        font-size: 0.8em;
        &--success {
            font-weight: 900;
            font-size: 1em;
        }

        &--error {
            color: red;
        }

        &--hidden {display: none;}
    }
}