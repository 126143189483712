.news-page {
  &__description {
    font-size: 1.2em;
    margin: 0 0 30px;
    line-height:1.4em;
    font-weight: bold;
  }

  &__content {
    margin: 30px 0;
  }

  &__body {
    display: flex;
    flex-direction: row;
  }

  &__right {
    float: right;
  }

  &__headline {
    margin: 5px 0;
    padding-top: 10px;
  }

  &__documents {
    border-bottom: 1px dotted;
    margin: 5px 0;
  }

  &__bottom {
    margin-top: 20px;
  }
}
