.partner-section{
  text-align:center;
  display:flex;
  flex-direction:column;
  padding: 10px 20px 0;
  margin: 0 auto;

  @media (min-width: $small-breakpoint) {
      flex-wrap: wrap;
      flex-direction:row;
  }
  &__header{
    width: 100%;
    font-size: 2.6em;
  }
  &__item{
    box-sizing: border-box;
    margin: 30px 0;
    padding: 0 10px;
    &__content {
        margin: 0 5px;
    }

    @media screen and (min-width: $small-breakpoint + 1) and (max-width: $medium-breakpoint) {
      flex-basis: 50%;
      max-width: 50%;
    }
    @media screen and (min-width: $medium-breakpoint + 1) and (max-width: $large-breakpoint) {
      flex-basis: 33.333333%;
      max-width: 33.333333%;
    }
   }
}
